import * as yup from 'yup';

export const shippingValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Enter your first name')
    .matches(/^[aA-zZ\s]+$/, 'First name must contain only letters'),
  lastName: yup
    .string()
    .required('Enter your last name')
    .matches(/^[aA-zZ\s]+$/, 'First name must contain only letters'),
  address: yup.string().required('Enter your address'),
  city: yup.string().required('Enter your city'),
  // country: yup.string().required('Select a country'),
  state: yup.string().required('Select a state'),
  zipCode: yup
    .string()
    .min(5, 'Too short')
    .max(5, 'Too long')
    .matches(/^\d+$/, 'Please enter a valid zip code')
    .required('Enter your zip code')
});

export const emailFormValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('Enter your first name')
    .matches(/^[aA-zZ\s]+$/, 'First name must contain only letters'),
  lastName: yup
    .string()
    .required('Enter your last name')
    .matches(/^[aA-zZ\s]+$/, 'Last name must contain only letters'),
  email: yup
    .string()
    .required('Required')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter a valid email'
    )
});

export const loginFormValidation = yup.object().shape({
  email: yup.string().required('Please enter your email/username'),
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Please enter your password')
});

export const signUpFormValidation = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup
    .string()
    .required('Required')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter a valid email'
    ),
  username: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z0-9]+$/, 'Usernames can only contain letters and numbers'),
  password: yup.string().min(8, 'Password must be at least 8 characters').required('Required'),
  agreeToTerms: yup.boolean().oneOf([true], `Please agree to Xureal's terms and conditions`)
});

export const spaceCreationValidation = yup.object().shape({
  name: yup.string().trim().required('Required').max(30, 'Name must be 50 characters or less').min(1, 'Required'),
  icon: yup.string().required('Please select or upload an icon for your space')
});
