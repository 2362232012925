import React, { useCallback, useEffect, useState } from 'react';
import { LoginStyles } from './Login.styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import xurealLogo from '../assets/images/white-logo.svg';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useAuth } from 'context/AuthContext';
import Button from '../common/Button';
// import GoogleIcon from 'assets/icons/login/googlelogo.png';
// import MicroSoftIcon from 'assets/icons/login/microsoft-brands.svg';
import { requestNewPassword, checkUserEmail, postGoogleAuth, getUserRedirect } from 'actions';
import { loginFormValidation } from 'utils/validation';
import RegularEye from 'assets/icons/login/eye.png';
import SlashEye from 'assets/icons/login/eye-slash.png';

// import GoogleLogin from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'node:querystring';

function Login({
  currentScreen,
  setCurrentScreen,
  fpt
}: {
  currentScreen: string;
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  fpt: string | null;
}) {
  const {
    initialized,
    authorizePlayer,
    checkFeatureEnabled,
    loginError,
    clientData,
    handleUserAuth,
    setRememberUser,
    redirectURL,
    setRedirectURL
  } = useAuth();
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPW, toggleShowPW] = useState(false);
  const [showError, setShowError] = useState('');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  const handlePWChange = useCallback(
    (e) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  useEffect(() => {
    const headElement = document.querySelector('head');
    const googleScriptEl = document
      .createElement('script')
      .setAttribute('src', 'https://apis.google.com/js/platform.js');
    if (headElement && googleScriptEl) {
      headElement.appendChild(googleScriptEl);
    }
  }, []);

  useEffect(() => {
    if (redirectURL) localStorage.removeItem('return_url');
    if (localStorage.getItem('return_url')) {
      setRedirectURL(localStorage.getItem('return_url'));
    }
  }, [redirectURL]);

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const handleResetSubmit = (email: string) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.toLowerCase().match(emailRegex)) {
      checkUserEmail(email).then((res: any) => {
        if (res.result === 'SUCCESS') {
          requestNewPassword(process.env.REACT_APP_API_SOURCE, { email: email, url: window.location.href })
            .then((res: { status: string; content: string }) => {
              setCurrentScreen('emailSent');
            })
            .catch((err: any) => console.log('Password reset error', err));
        }
        if (res.result === 'FAILED') {
          setShowError('This email is not associated with an account');
        }
      });
    }
    if (!email.toLowerCase().match(emailRegex)) {
      setShowError((prev) => (prev ? prev : 'Please enter a valid email'));
    }
  };

  useEffect(() => {
    setShowError(loginError);
  }, [loginError]);

  useEffect(() => {
    if (showError) {
      const errorTimeout = setTimeout(() => {
        setShowError('');
      }, 3000);

      return () => {
        clearTimeout(errorTimeout);
      };
    }
  }, [showError]);

  const onGoogleLogIn = (googleUser: any) => {
    postGoogleAuth(googleUser).then((data: any) => {
      if (data.result === 'SUCCESS') {
        handleUserAuth(data.content, true);
      }
    });
  };

  const handleSubmit = (email: string) => {
    fetch(`https://portal.xureal.com/promo-api/xrusers/email/${email}`)
    .then((response: any) => {
      return response.json()})
    .then((data) => {
        console.log('response', data)
        let navigateTo = (data.platform_url.startsWith('http')) ? data.platform_url : `https://${data.platform_url}`;
        console.log('navigate', navigateTo)
        let stringifiedObj = JSON.stringify(data);
        console.log('object sent', stringifiedObj)
        let newParam = window.btoa(stringifiedObj);
        console.log(navigateTo)
        window.location.href = `${navigateTo}?portal=${newParam}`
      console.log('results', data)})
    .catch(err => {
      setShowError('Email not found')
      console.log('error', err)})
  }

  return (
    <>
      {initialized && checkFeatureEnabled('login') && (
        // Component renders different screens based on currentScreen
        <LoginStyles.Container $isSmallScreen={isSmallScreen}>
       
          <LoginStyles.Main>
            {/* Default login screen below */}
            {currentScreen === 'default' && (
              <LoginStyles.Box>
                   <LoginStyles.LogoContainer>
            <LoginStyles.SmImg src={xurealLogo} style={{ display: 'block' }} />
          </LoginStyles.LogoContainer>
                <div style={{ width: '100%' }}>
                  <LoginStyles.Title> </LoginStyles.Title>
                </div>
                {/* {process.env.REACT_APP_GSI_ACCESS && (
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GSI_ACCESS}
                    render={(renderProps: any) => (
                      <LoginStyles.SSOButtons
                      style={{opacity: '.5', cursor: 'default'}}
                        // onClick={renderProps.onClick}
                      >
                        <LoginStyles.SSOIcon src={GoogleIcon} />
                        Continue with Google
                      </LoginStyles.SSOButtons>
                    )}
                    onSuccess={onGoogleLogIn}
                    onFailure={() => console.log('failed')}
                    isSignedIn={true}
                    cookiePolicy={'single_host_origin'}
                  />
                )}

                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <LoginStyles.PartialBorder />
                  <span style={{ fontSize: '12px', color: '#8C8C9B', margin: '0 12px' }}>OR</span>
                  <LoginStyles.PartialBorder />
                </div> */}
                <Formik
                  initialValues={{
                    email: ''
                  }}
                  validationSchema={loginFormValidation}
                  validateOnBlur={false}
                  onSubmit={(values) => handleSubmit(values.email)}
                >
                  {({ values, validateForm, handleChange }) => {
                    return (
                      <Form style={{ width: '100%' }}>
                        <LoginStyles.Error>{showError}</LoginStyles.Error>
                        <LoginStyles.TextFieldsContainer style={{ marginBottom: '20px' }}>
                          <Field
                            onChange={(e: any) => {
                              handleChange(e);
                              handleEmailChange(e);
                            }}
                            fullWidth
                            variant='filled'
                            label='Email'
                            type='text'
                            disabled={false}
                            component={TextField}
                            size='small'
                            name='email'
                            id='email'
                          />
                        </LoginStyles.TextFieldsContainer>
                        <Button
                          submitType
                          customStyles={{
                            width: '50%',
                            borderRadius: '30px !important',
                            height: '52px',
                            marginBottom: '32px',
                            background: '#FFFFFF',
                            color: '#000000 !important',
                            fontSize: '16px',
                            fontWeight: '600',
                            lineHeight: '0px',
                            margin: 'auto',
                            marginTop: '30px',
                            display: 'block',
                            border: '1px solid rgba(255, 255, 255, 0.15)'
                          }}
                          handleClick={() => {
                            validateForm().then(() => handleSubmit(values.email));
                          }}
                          buttonText={'Enter'}
                        />
                      </Form>
                    );
                  }}
                </Formik>

                {/* <LoginStyles.Subtext $isSmallScreen={isSmallScreen}>
                  {`Don't have an account?`}
                  <LoginStyles.ActionTextSpan
                    $isSmallScreen={isSmallScreen}
                    onClick={() => navigate('../signup', { replace: true })}
                    style={{ marginLeft: 15 }}
                  >
                    Create one here
                  </LoginStyles.ActionTextSpan>
                </LoginStyles.Subtext> */}
                {/* {isSmallScreen && (
                <LoginStyles.Disclaimer $isSmallScreen={isSmallScreen}>
                  This site is protected by reCAPTCHA and the{' '}
                  <a target='_blank' rel='noreferrer' href='https://policies.google.com/privacy?hl=en-US'>
                    Google Privacy Policy
                  </a>{' '}
                  and Terms of Service apply.
                </LoginStyles.Disclaimer>
              )} */}
              </LoginStyles.Box>
            )}
          </LoginStyles.Main>
          <LoginStyles.Footer>&copy; 2023 Xureal. All rights reserved.</LoginStyles.Footer>
        </LoginStyles.Container>
      )}
    </>
  );
}

export default Login;
