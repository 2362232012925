import React from 'react'; // useEffect // useCallback, // useState,
import styled from 'styled-components';
import Button from '@mui/material/Button';

type ButtonProps = {
  handleClick?: () => void;
  disabled?: boolean;
  href?: string;
  buttonText: string;
  buttonType?: 'primary' | 'secondary';
  submitType?: boolean;
  customStyles: any;
  icon?: string;
};

const ButtonStyles = {
  Root: styled(Button)`
    font-family: Xureal;
    border-radius: 17px !important;
    padding: 20px 26px !important;
    color: #ffffff !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.11px;
    line-height: 20px;
    text-align: center;
    background: linear-gradient(#435f63, #1e222a);
    transition: 0.3s;
    &:hover {
      background-color: #f5f5f5 !important;
    }
  `
};

const StandardButton: React.FC<ButtonProps> = ({
  customStyles,
  disabled,
  handleClick,
  buttonText,
  href,
  buttonType,
  submitType,
  icon
}) => {

  return (
    <ButtonStyles.Root
      disabled={disabled}
      sx={customStyles}
      onClick={handleClick}
      href={href}
      type={submitType ? 'submit' : 'button'}
    >
      {icon && <img style={{height: '14px', width: 'auto', marginRight: '8px'}} src={icon} alt='copy url'/>}
      {buttonText}
    </ButtonStyles.Root>
  );
};

export default StandardButton;
