import TextField from '@mui/material/TextField';
import styled from 'styled-components'; // Use this for regular element styling
import { styled as materialStyled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

export const LoginStyles = {
  Container: styled.div<{ $isSmallScreen: boolean }>`
    background-color: rgba(255,255,255,0.25);
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    z-index:1;
  `,

  Main: styled.div`
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
  `,

  Box: styled.div`
    background-color: rgba(0,0,0,0.8);
    padding: 76px 35px;
    color: #FFFFFF;
    width: 480px;
    max-width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 21px;
  `,

  ColorBlock: styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35%;
    background-color: #000000;
    z-index: -1;
  `,

  Title: styled.div`
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 32px;
    font-weight: bold;
    text-align: center;
    @media (max-width: 480px) {
      font-size: 28px;
    }
  `,

  LogoContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media (max-width: 480px) {
      justify-content: center;
    }
    z-index:2;
  `,
  SmImg: styled.img`
    width: 180px;
    margin: auto;
    @media (max-width: 480px) {
      margin: auto;
      width: 100.77px;
    }
  `,

  Icon: styled.img`
    width: 50px;
    margin: 63px auto 48px;
  `,

  Border: styled.div`
    height: 1px;
    width: 100%;
    background-color: #e1e0e7;
    margin: 18px 0;
  `,

  PartialBorder: styled.div`
    display: inline;
    height: 1px;
    width: 40%;
    background-color: #e1e0e7;
    margin: 23px 0;
    flex: 1;
  `,

  TextFieldRow: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
  `,

  TextFieldsContainer: styled.div<{ mobileLayout?: boolean; $isSecondChild?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: ${(props) => (!props.$isSecondChild ? '0' : props.mobileLayout ? '0' : '20px')};
    margin-bottom: 12px;
    margin-top: ${(props) => (props.$isSecondChild && props.mobileLayout ? '16px' : '0')};
  `,

  TextArea: materialStyled(TextField, {
    shouldForwardProp: (prop) => prop !== '$btmmargin'
  })((props: { $btmmargin: string }) => ({
    marginBottom: `${props.$btmmargin} !important`,
    '.MuiFormHelperText-root': {
      top: '100%',
      bottom: 'auto'
    }
  })),

  CheckIcon: styled.img`
    height: 20px;
    width: 20px;
    margin-right: 12px;
  `,

  EyeIcon: styled.img`
    height: 20px;
    width: 20px;
    margin-right: 12px;
    cursor: pointer;
    position: absolute;
    right: 0;
  `,

  Error: styled.div`
    font-size: 12px;
    text-align: left;
    width: 100%;
    color: red;
  `,

  ActionContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 24px 0;
  `,

  ActionText: styled.div`
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  `,

  ActionTextSpan: styled.span<{ $isSmallScreen?: boolean }>`
    font-size: 14px;
    font-weight: 500;
    color: #3b2ec9;
    cursor: pointer;
    margin-left: ${(props) => (props.$isSmallScreen ? '0' : '4px')};
    @media (max-width: 330px) {
      margin-left: 0px !important;
    }
  `,

  LoginHeader: styled.div<{ $isSmallScreen?: boolean }>`
    font-size: ${(props) => (props.$isSmallScreen ? '22px' : '28px')};
    font-weight: 500;
    width: 100%;
    line-height: 50px;
    margin-bottom: 11px;
    text-align: left;
  `,

  Instructions: styled.div<{ $isSmallScreen?: boolean }>`
    font-size: ${(props) => (props.$isSmallScreen ? '11px' : '13px')};
    line-height: 20px;
    color: #474d5d;
    width: 100%;
    text-align: center;
    // margin-bottom: 48px;
    @media (max-width: 480px) {
      font-size: 14px !important;
    }
  `,

  SurveyHeader: styled.div`
    font-size: 42px;
    font-weight: bold;
    line-height: 44px;
    width: 100%;
    text-align: center;
  `,

  SurveyInstructions: styled.div`
    font-size: 18px;
    line-height: 22px;
    color: #3e4459;
    width: 100%;
    text-align: center;
    font-weight: 400;
    margin: 16px 0 35px 0;
  `,

  CreateAccountSubtext: styled.div<{ $isSmallScreen?: boolean }>`
    /* background-color: #ecf1ff; */
    border-radius: 12px;
    padding: 0px 50px;
    display: flex;
    flex-direction: ${(props) => (props.$isSmallScreen ? 'column' : 'row')};
    justify-content: center;
    align-items: ${(props) => (props.$isSmallScreen ? 'center' : 'flex-start')};
    font-size: 14px;
    line-height: 27px;
    font-weight: 500;
    color: #777e91;
    /* margin-top: 16px; */
  `,

  Subtext: styled.div<{ $isSmallScreen?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 20px;
    font-weight: 500;
    color: #777e91;
    flex-direction: row;
    @media (max-width: 330px) {
      flex-direction: column;
    }
  `,

  EmailScreenContainer: styled.div<{ $emailCopy: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: ${(props) => (props.$emailCopy === 'signUpEmail' ? '357px' : '100%')};
  `,

  SSOContainer: styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,

  SSOButtons: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    width: 100%;
    border-radius: 8px;
    border: 1px rgb(140, 140, 155) solid;
    cursor: pointer;
  `,

  SSOIcon: styled.img`
    height: 20px;
    width: 20px;
    margin-right: 11px;
  `,

  BackIcon: styled.img`
    display: inline;
    height: 13px;
    margin-right: 8px;
  `,

  Disclaimer: styled.div<{ $isSmallScreen: boolean }>`
    color: #474d5d;
    font-size: ${(props) => (props.$isSmallScreen ? '11px' : '13px')};
    margin-top: 20px;
    transform: translate(50%);
    text-align: center;
    width: ${(props) => (props.$isSmallScreen ? '90%' : '100%')};
  `,

  Background: styled.div`
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `,

  Footer: styled.div`
    width: 100%;
    height: 43px;
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: medium;
    z-index: 11;
  `,

  Policy: styled.div`
    font-size: 12px;
    margin-bottom: 20px;
    text-align: center;
    color: #777e91;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,

  PinkGradient: styled.img`
    opacity: 0.4;
    max-width: none;
    z-index: -10;
    filter: blur(40px);
    position: fixed;
    right: 0;
    left: auto;
    transform: translate(60%, 10%);
  `,

  BlueGradient: styled.img`
    opacity: 0.4;
    max-width: none;
    z-index: -10;
    filter: blur(40px);
    position: fixed;
    right: auto;
    left: 0;
    transform: translate(-55%, 10%);
  `,
  BoxButtonContainer: styled.div<{ $selected: boolean }>`
    height: 56px;
    width: 271px;
    padding: 19px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    border: ${(props) => (props.$selected ? 'none' : '1px #e1e0e7 solid')};
    cursor: pointer;
    background: ${(props) => (props.$selected ? `#3b2ec9` : '#ffffFF')};
    color: ${(props) => (props.$selected ? '#ffffff' : '#323744')};
    &:hover {
      background-color: ${(props) => (props.$selected ? '3b2ec9' : '#ECF1FF')};
    }
  `,
  BoxButtonText: styled.p`
    font-size: 20px;
  `,
  Checkbox: materialStyled(Checkbox)`
    background-color: #FFFFFF;
    margin-right: 12px;
    &.Mui-checked {
      color: #FFFFFF;
    }
  `
};
