export const capitalizeString = (inputString: string) => {
  if (typeof inputString !== 'string') return inputString;
  return inputString[0].toUpperCase() + inputString.slice(1).toLowerCase();
};

export function secondsToTime(e: number) {
  var m = Math.floor((e % 3600) / 60).toString(),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');

  return m + ':' + s;
}

export function getHoursTimeDiff(startDate: any, endDate: any) {
  return Math.floor(Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) / 36e5);
}

export function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function round500(x: number) {
  if (x === 0) return 500;
  if (x % 500 === 0) return x + 500;
  else return Math.ceil(x / 500) * 500;
}
