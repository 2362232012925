import React from 'react';
import styled from 'styled-components';

// Components
import EntryRoutes from 'EntryRoutes/EntryRoutes';

const LayoutStyles = {
  LayoutContainer: styled.div`
    height: 100%;
    width: 100vw;
    display: flex;
    background-color: #ffffff;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `
};

const Layout: React.FC = () => {
  
  return (
    <LayoutStyles.LayoutContainer>
      <EntryRoutes />
    </LayoutStyles.LayoutContainer>
  );
};

export default Layout;
