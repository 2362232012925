////////////////////////////////////////////////////////////////
//! Application Initialization
//
//* The order of context is vital to not causing multiple
//*   rerenders in the application, as well as ensuring each
//*   context is provided the correct data. The Content context
//*   is above the Application context because the Application
//*   context holds the onmessage logic, which relies on having
//*   the content
//
////////////////////////////////////////////////////////////////

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ApplicationProvider from 'context/AppContext';
import AuthenticationProvider from 'context/AuthContext';
import ContentProvider from 'context/ContentContext';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight);

ReactDOM.render(
  <React.StrictMode>
    <AuthenticationProvider>
      {/* Authenticate User and Initialize API */}
      <ContentProvider>
        {/* Retrieve content from API */}
        <ApplicationProvider>
          {/* Control the data/ flow of the top and bottom layers */}
          {/* TODO insert a context to control all UI updates */}
          <App />
        </ApplicationProvider>
      </ContentProvider>
    </AuthenticationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
