import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'theme';

const App: React.FC = () => {
  return (
    <Router basename='/'>
      <div style={{height: '100%'}}>
        <ThemeProvider theme={theme}>
            <Layout />
        </ThemeProvider>
      </div>
    </Router>
  );
};

export default App;
