import { useAuth } from 'context/AuthContext';
import Login from 'Login/Login';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

const EntryRoutes = () => {
  let navigate = useNavigate();
  const [fpt] = useState(new URLSearchParams(window.location.search).get('fpt'));
  const [currentScreen, setCurrentScreen] = useState(fpt ? 'newPW' : 'default');
  const location = useLocation();

  const { authenticated } = useAuth();
  useEffect(() => {
    if (authenticated) {
      window.location.href = 'https://play.dev.xureal.com';
    } else {
        navigate('../', { replace: true });
    }
  }, [authenticated]);

  return (
    <Routes>
      <Route path='/' element={<Login currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} fpt={fpt} />} />
    </Routes>
  );
};

export default EntryRoutes;
