////////////////////////////////////////////////////////////////
//! Content Context
//
//* This context will provide data that pertains to the content
//*   (avatars, signage, hotspots, etc) that gets loaded from the CMS.
//*   This context should pull all data needed after API is
//*   initialized, so it can be ready to pass down to Unity
//*
//
////////////////////////////////////////////////////////////////

import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import { getAllApps, getFeaturedApps } from 'actions';
import { useAuth } from './AuthContext';
import { ContentContext, Tenant } from 'types/Content/ContentTypes';

export const ContentCtx = createContext({});

const ContentProvider: React.FC = ({ children }) => {
  const { authenticated, userData, checkFeatureEnabled, clientData } = useAuth();
  const [content, setContent] = useState({ allDestinations: [], featuredDestinations: [], spaceIcons: [] });
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [fetched, setFetched] = useState(false);

  const [showAvatarFrame, setShowAvatarFrame] = useState(localStorage.getItem('avatar_url') ? true : false);
  const [avatarData, setAvatarData] = useState<{ config: any; textureURL: string; thumbnail: string; loaded: boolean }>(
    {
      config: {},
      textureURL: '',
      thumbnail: '',
      loaded: false
    }
  );
  const avatarLayerRef = useRef<any>(null);
  const [guidedElementsLoaded, setGuidedElementsLoaded] = useState(0);

  const handleOpenAvatarFrame = useCallback(() => {
    setShowAvatarFrame(true);
  }, [setShowAvatarFrame]);

  const handleCloseAvatarFrame = useCallback(
    (data: any) => {
      setAvatarData({ ...data, loaded: true });
      setShowAvatarFrame(false);
    },
    [setShowAvatarFrame]
  );

  useEffect(() => {
    if (!authenticated) return;
    if (!checkFeatureEnabled('avatar') && clientData?.config?.multiplayer === 'disabled') {
      setAvatarData({
        config: {},
        textureURL: '',
        thumbnail: '',
        loaded: true
      });
      return;
    }

    // call to fetch user avatar data
    const AVATAR_URL = process.env.REACT_APP_AVATAR_URL;
    const avatarURL = `${AVATAR_URL}avatars/users/${userData.xurealID}`;

    if (!localStorage.getItem('avatar_url')) {
    fetch(avatarURL)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          handleCloseAvatarFrame({
            config: data[0].data,
            textureURL: data[0].dataURL,
            thumbnail: data[0].thumbnailURL
          });
        } else {
          if (clientData?.config?.multiplayer === 'enabled') {
            handleOpenAvatarFrame();
          } else {
            setAvatarData({
              config: {},
              textureURL: '',
              thumbnail: '',
              loaded: true
            });
          }
        }
      })
      .catch((err) => {
        console.error('Avatar fetch error', err);
      });
    }
    const onAvatarClose = (cb: (data: any) => void) => {
      const listener = (event: { data: any }) => {
        if (event.data.platform === 'xureal-avatar-app') {
          cb(event.data);
        }
      };

      window.addEventListener('message', listener);
      return () => window.removeEventListener('message', listener);
    };

    const offAvatar = () => 
      
      onAvatarClose((data) => {
        const returnURL = localStorage.getItem('avatar_url');
        if (returnURL) {
          window.location.href = returnURL;
        };
        // handle logic for avatar iframe handling here
        if (data.message === 'skipped') {
          handleCloseAvatarFrame({
            config: '',
            textureURL: '',
            thumbnail: ''
          });
        } else if (data.message === 'closed') {
          setShowAvatarFrame(false);
        } else {
          setTimeout(() => {
            handleCloseAvatarFrame({
              config: data.message.userAvatar.avatarData,
              textureURL: data.message.userAvatar.dataURL,
              thumbnail: data.message.userAvatar.thumbnailURL
            });
          }, 2500);
        }
      });
    offAvatar();
    return () => {
      offAvatar();
    };
  }, [
    authenticated,
    handleCloseAvatarFrame,
    setAvatarData,
    handleOpenAvatarFrame,
    userData.xurealID,
    checkFeatureEnabled,
    clientData.config.multiplayer
  ]);

  const fetchContentData = useCallback(() => {
    const getAllDestinations = getAllApps();
    const getFeaturedDestinations = getFeaturedApps();

    Promise.all([getAllDestinations, getFeaturedDestinations]).then((values) => {
      const [allDestResults, featuredDestResults] = values;
      console.log(values)
      setFetched(true);
    });
    // eslint-disable-next-line
  }, []);

  // TODO: check if user has an avatar saved, continue and call handleCloseAvatarFrame() if true

  useEffect(() => {
    if (!authenticated) return;
    if (!checkFeatureEnabled('avatar') && clientData?.config?.multiplayer === 'disabled') {
      setAvatarData({
        config: {},
        textureURL: '',
        thumbnail: '',
        loaded: true
      });
      return;
    }
    // call to fetch user avatar data
    const AVATAR_URL = process.env.REACT_APP_AVATAR_URL;
    const avatarURL = `${AVATAR_URL}avatars/users/${userData.xurealID}`;

    if (!localStorage.getItem('avatar_url')) 
    fetch(avatarURL)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          handleCloseAvatarFrame({
            config: data[0].data,
            textureURL: data[0].dataURL,
            thumbnail: data[0].thumbnailURL
          });
        } else {
          if (clientData?.config?.multiplayer === 'enabled') {
            handleOpenAvatarFrame();
          } else {
            setAvatarData({
              config: {},
              textureURL: '',
              thumbnail: '',
              loaded: true
            });
          }
        }
      })
      .catch((err) => {
        console.error('Avatar fetch error', err);
      });
  }, [
    authenticated,
    handleCloseAvatarFrame,
    setAvatarData,
    handleOpenAvatarFrame,
    userData.xurealID,
    checkFeatureEnabled,
    clientData.config.multiplayer
  ]);
  useEffect(() => {
    //! if the user is not authenticated, if the user has not set or skipped an avatar, or if the data has already been fetched, do not proceeed
    if (!authenticated || !avatarData.loaded || fetched) return;

    fetchContentData();
  }, [authenticated, fetched, fetchContentData, avatarData]);

  return (
    <ContentCtx.Provider
      value={{
        content,
        setContent,
        fetched,
        setFetched,
        handleCloseAvatarFrame,
        handleOpenAvatarFrame,
        avatarLayerRef,
        setGuidedElementsLoaded,
        guidedElementsLoaded,
        setShowAvatarFrame,
        showAvatarFrame,
        avatarData,
        tenants,
        setTenants
      }}
    >
      {children}
    </ContentCtx.Provider>
  );
};

export const useContent = () => {
  return useContext(ContentCtx) as ContentContext;
};

export default ContentProvider;
