////////////////////////////////////////////////////////////////
//! Application Context
//
//* This context will be used to control the message system
//*   to communicate between the bottom and top layers, the
//*   actions required to enable this communication, and any
//*   other controlled values to display to the user Ex:
//*   points, leaderboards, catalogs, etc
//*
//
////////////////////////////////////////////////////////////////
import React, { useCallback, createContext, useState, useContext, useEffect, useRef } from 'react';
import {
  retrieveLeaderboard,
  getPlayerCurrency,
} from 'actions';
import { useContent } from './ContentContext';
import { useAuth } from './AuthContext';
import {
  AppContext,
  ChatDataType,
  LeaderboardUserType,
} from 'types/Application/ApplicationTypes';
export const AppCtx = createContext<any>({});

const ApplicationProvider: React.FC = ({ children }) => {

  const { fetched, setFetched } = useContent();
  const { clientData, userData, checkFeatureEnabled } =
    useAuth();

  const [, setIframeLoaded] = useState(false);
  const [chatData, setChatData] = useState<ChatDataType[]>([]);
  const [progress, setProgress] = useState(0);

  const [showUniversalModal, setShowUniversalModal] = useState(false);

  const [points, setPoints] = useState(0);
  const [tokens, setTokens] = useState<number>(0);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardUserType[]>([]); //showcase related state
  const [currentHostPage, setCurrentHostPage] = useState(1); // state for tracking current host navigated pdf page

  const firstMount = useRef(true);
  const unityLayerRef = useRef<HTMLIFrameElement>(null);
  const pointsId = 'points';

  const onIframeLoad = useCallback(() => {
    setIframeLoaded(true);
  }, []);

  const onTeleportClick = useCallback((index: number) => {
    const message = JSON.stringify({
      unityComponent: 'Managers',
      componentFunction: 'Teleport',
      message: index
    });
    unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
  }, []);

  const onMapToggle = useCallback((index: number) => {
    const message = JSON.stringify({
      unityComponent: 'Managers',
      componentFunction: 'OpenScreen',
      message: index
    });
    unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
  }, []);

  const onSendChat = useCallback((data: ChatDataType) => {
    const message = JSON.stringify({
      platform: 'xureal-experience',
      action: 'xureal-chat-send',
      data: data
    });
    setChatData((prev) => [...prev, data]);
    unityLayerRef?.current?.contentWindow?.postMessage(message, '*');
  }, []);

  useEffect(() => {
    if (!fetched || firstMount.current === false || !checkFeatureEnabled('points')) return;

    getPlayerCurrency({
      level: userData.role === 1 ? 1 : 3
    })
      .then((res: any) => {
        if (res.result === 'FAILED') {
          return;
        }

        setTokens(res.content.currency / 100);
      })
      .catch((err: any) => console.error('Player Currency Error', err)); // call to get leaderboard data and grab latest user points if they exist

    retrieveLeaderboard(pointsId)
      .then((data: LeaderboardUserType[]) => {
        let sortedArr: LeaderboardUserType[] = data.sort((a: any, b: any) => b.stats.points - a.stats.points);
        setLeaderboardData(sortedArr);
        const userLeaderboardData = data.filter((el: any) => el.xurealID === userData.xurealID);
        if (userLeaderboardData.length > 0) setPoints(userLeaderboardData[0].stats.points);
      })
      .catch((err: any) => console.error('Leaderboard error', err)); // states will be "" (not started), "started", "stopped", "finished", "completed"

    
    firstMount.current = false;
  }, [
    fetched,
    setFetched,
    userData,
    checkFeatureEnabled,
    clientData,
  ]); // this function sets up all the postMessage listeners using the MessageService class

  return (
    <AppCtx.Provider
      value={{
        progress,
        setProgress,
        showUniversalModal,
        // TODO: Move to UI Context?
        setShowUniversalModal,
        // TODO: Move to UI Context?
        onIframeLoad,
        unityLayerRef,
        points,
        tokens,
        setTokens,
        leaderboardData,
        onTeleportClick,
        onMapToggle,
        currentHostPage,
        setCurrentHostPage,
        onSendChat,
        chatData
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};

export const useApp = () => {
  return useContext(AppCtx) as AppContext;
};
export default ApplicationProvider;
