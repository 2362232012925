import { AnalyticsObjectType } from 'types/Analytics/AnalyticsTypes';
import { CompletedProgressData, RegisterUserTypes } from 'types/Auth/AuthTypes';
import { TransactionDataType } from 'types/Catalog/CatalogTypes';
import { CreateTenantType } from 'types/Content/ContentTypes';

const API_SOURCE = process.env.REACT_APP_API_SOURCE;
const API_KEY = process.env.REACT_APP_API_KEY;

declare var xurealAPI: any;

export const initApiClient = () => {
  return new Promise((resolve, reject) => {
    try {
      xurealAPI.init(API_SOURCE, API_KEY);
      resolve('SUCCESS');
    } catch (e) {
      console.error(e);
      reject('ERROR');
    }
  });
};

export const registerUser = (playerData: RegisterUserTypes) => xurealAPI.postPlayerData('', playerData);

export const getUserRedirect = (email: string) => xurealAPI.getUserRedirect(email);

export const getLoginMode = (url: string) => xurealAPI.getLoginMode(url);

export const checkUserEmail = (email: string) => xurealAPI.checkPlayerData('email', email);
export const whitelistCheck = (email: string) => xurealAPI.checkTesterData('email', email);
export const checkUsername = (username: string) => xurealAPI.checkPlayerData('username', username);

export const getGuidedTourData = (appEnvID: string, pageID: string) => {
  return xurealAPI.getGuidedTourData(appEnvID, pageID);
};

export const getPlayerCompletedGuidedTours = (userID: string) => xurealAPI.getPlayerCompletedGuidedTours(userID);

export const updatePlayerCompletedGuidedTours = (xurealID: string, tourID: string) =>
  xurealAPI.updatePlayerCompletedGuidedTours({ xureal_id: xurealID, tour_id: tourID });

export const postPlayerAuth = (playerData: {
  username?: string;
  password: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  login_type?: string;
  customID?: string;
  role?: number;
  data?: {};
}) => xurealAPI.postPlayerAuthentication('', playerData);

export const postPlayerData = (playerData: {
  username: string;
  password: string;
  email: string;
  firstname: string;
  lastname: string;
  phone?: string;
  customID?: string;
  url: string;
  data?: {};
}) => xurealAPI.postPlayerData('', playerData);

export const postGoogleAuth = (googleUser: any) => xurealAPI.onGoogleAuth(googleUser);

// Hub Explore Destination Calls

export const getAllApps = () => xurealAPI.getHubAppData('all');
export const getFeaturedApps = () => xurealAPI.getHubAppData('featured');
export const getAppsByCategory = (category: string) => xurealAPI.getHubAppData('by_category', category);
export const getAppsByTenant = (tenantID: string) => xurealAPI.getHubAppData('by_tenant', tenantID);

export const requestNewPassword = (url: string | undefined, emailObj: { email: string; url: string }) =>
  xurealAPI.postPlayerForgotPassword(url, emailObj);
export const submitNewPassword = (
  url: string | undefined,
  passwordObj: { fpid: string; password: string; username: string }
) => xurealAPI.postPlayerResetPassword(url, passwordObj);

export const resendSignUpEmail = (resendObj: {
  xureal_id: string;
  fname: string;
  lname: string;
  email: string;
  url: string;
}) => xurealAPI.postResendConfirmation(resendObj);

// Social calls

export const getUserConnections = (reqObj: { xureal_id: string }) => xurealAPI.getMySocialConnections(reqObj);
export const requestConnection = (reqData: { xureal_id_s: string, xureal_id_r: string}) => xurealAPI.sendSocialRequestByID(reqData);


export const updateAuthTokens = (tokenData: { JWT_TOKEN: string; REFRESH_TOKEN: string }) =>
  xurealAPI.updateTokens(tokenData);

export const getPlayerProgress = () => xurealAPI.getPlayerDetails();

export const setPlayerProgress = (playerProgress: CompletedProgressData[]) =>
  xurealAPI.postPlayerDetails(playerProgress);

export const updatePoints = (scoreData: { [key: string]: number }) => xurealAPI.postPlayerStatistic('', scoreData);

export const retrieveLeaderboard = (pointsId: string) => xurealAPI.getLeaderboardData(pointsId);

export const getHotspotData = () => xurealAPI.getHotspotDataAll();

export const getHotspotByLocation = (location: string) => xurealAPI.getHotspotsLocationContentDetails(location);

export const getClientData = () => xurealAPI.getClientDataAll();

export const getConfigData = (configObj: { application_id: string; tenant_id: string }) =>
  xurealAPI.getConfigData(configObj);

export const getWorldThemesAll = () => xurealAPI.getWorldThemesAll();

export const getPlayerCurrency = (currencyObj: { level: number }) => xurealAPI.getPlayerCurrency(currencyObj);

export const updateCurrency = (currObj: { level: number; amount: number; method: string }) =>
  xurealAPI.updatePlayerCurrency(currObj);

export const sendAnalytics = (analyticsObj: AnalyticsObjectType) => xurealAPI.postXRnalytics(analyticsObj);

